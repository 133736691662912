import React, { useContext, useMemo } from 'react';
import Lottie from 'lottie-react';
import clss from 'classnames';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { LanguageContext } from '../../context/LanguageContext';
import Typography from 'components/atoms/typography';
import GetStarted from '../../components/getstarted/GetStarted';
import shieldIcon from './images/shield.svg';
import phoneImgCN from './images/phone_cn.png';
import phoneImgEN from './images/phone_en.png';
import keyNormalSVG from './images/key_normal.svg';
import encryptionLottie from './lottie/encryption.json';
import inputGroupLottieEN from './lottie/input_group_en.json';
import inputGroupLottieCN from './lottie/input_group_cn.json';
import databaseLottie from './lottie/database.json';
import dataLottie from './lottie/data.json';
import keyLottie from './lottie/key.json';
import styles from './security.module.scss';

function StageNumber({ stage }) {
  return (
    <div className={styles.stageNumber}>
      <Typography
        lgSize="h1"
        size="h1"
        xlSize="hero1"
        weight="bold"
        color="grey"
      >
        {stage}
      </Typography>
    </div>
  );
}

function StageDescription({ mainText, subText, mainClass }) {
  return (
    <div className={styles.stageDescriptionWrapper}>
      <div className={clss(styles.stageDescription, mainClass)}>
        <Typography size="h1" wrap={true} weight="medium">
          {mainText}
        </Typography>
      </div>
      <div className={styles.stageDescriptionSub}>
        <Typography size="title1" wrap={true} color="heading">
          {subText}
        </Typography>
      </div>
    </div>
  );
}

function Security() {
  const { t, i18n } = useTranslation();

  const { phoneImg, inputGroupLottie } = useMemo(() => {
    const isEN = i18n.language === 'en';
    return {
      phoneImg: !isEN ? phoneImgCN : phoneImgEN,
      inputGroupLottie: !isEN ? inputGroupLottieCN : inputGroupLottieEN,
    };
  }, [i18n.language]);

  return (
    <>
      <Container fluid>
        <Row className={styles.header}>
          <div className={styles.headerShield}>
            <img src={shieldIcon} alt="" className={styles.headerShieldIcon} />
            <div className={styles.headerShieldTextWrapper}>
              <Typography xlSize="hero1" size="xh1" lgSize="xh1" wrap={true}>
                {t('security.title')}
              </Typography>
            </div>
          </div>
        </Row>

        <div className={styles.mainContainer}>
          <div className={styles.securityFlowAnimationBoard}>
            <div className={clss(styles.securityFlowBg)}>
              <div
                className={clss(
                  styles.securityFlowBgLeft,
                  styles.securityFlowBgGreyItem
                )}
              ></div>
              <div
                className={clss(
                  styles.securityFlowBgMiddle,
                  styles.securityFlowBgGreyItem
                )}
              ></div>
              <div
                className={clss(
                  styles.securityFlowBgRight,
                  styles.securityFlowBgGreyItem
                )}
              ></div>
            </div>
            <div
              className={clss(
                styles.securityFlowBg,
                styles.securityFlowCyan,
                styles.flowSliderStart
              )}
            >
              <div
                className={clss(
                  styles.securityFlowBgLeft,
                  styles.securityFlowBgCyanItem
                )}
              ></div>
              <div
                className={clss(
                  styles.securityFlowBgMiddle,
                  styles.securityFlowBgCyanItem
                )}
              ></div>
              <div
                className={clss(
                  styles.securityFlowBgRight,
                  styles.securityFlowBgCyanItem
                )}
              ></div>
            </div>
          </div>
          <div className={styles.securityFlowContent}>
            <Row className={styles.stage0Placeholder}></Row>
            <Row className={styles.stage1Row}>
              <Col className={styles.stage1DescriptionCol}>
                <StageNumber stage="01" />
                <StageDescription
                  mainText={t('security.stage1.description')}
                  subText={t('security.stage1.descriptionSub')}
                />
              </Col>
              <Col className={styles.inputGroupCol}>
                <Row>
                  <Lottie
                    animationData={inputGroupLottie}
                    className={styles.inputGroupLottie}
                  />
                </Row>
              </Col>
              <img src={keyNormalSVG} alt="" className={styles.stage1Key} />
            </Row>
            <Row className={styles.stage2Row}>
              <Col className={styles.encryptionLottieWrapper}>
                <div className={styles.stage2EncryptionShadow}></div>
                <Lottie
                  className={styles.encryptionLottie}
                  animationData={encryptionLottie}
                />
              </Col>
              <Col className={styles.stage2DescriptionCol}>
                <StageNumber stage="02" />
                <StageDescription
                  mainText={t('security.stage2.description')}
                  subText={t('security.stage2.descriptionSub')}
                  mainClass={styles.stage2Description}
                />
              </Col>
              <Lottie animationData={keyLottie} className={styles.stage2Key} />
            </Row>
            <Row className={styles.stage3Row}>
              <Col className={styles.stage3DescriptionCol}>
                <StageNumber stage="03" />
                <StageDescription
                  mainText={t('security.stage3.description')}
                  subText={t('security.stage3.descriptionSub')}
                />
              </Col>
              <Col className={styles.databaseColumn}>
                <Row className={styles.databaseRow}>
                  <div className={styles.databaseLottieWrapper}>
                    <Lottie
                      className={styles.databaseLottie}
                      animationData={databaseLottie}
                    />
                    <div className={styles.stage3DatabaseShadow}></div>
                  </div>
                </Row>
              </Col>
              <Lottie
                animationData={dataLottie}
                className={styles.stage3Data}
              />
            </Row>
            <Row className={styles.stage4Row}>
              <Col className={styles.stage4EncryptionContainer}>
                <Lottie
                  className={clss(
                    styles.encryptionLottie,
                    styles.encryptionLottieStage4
                  )}
                  animationData={encryptionLottie}
                />
              </Col>
              <Col className={styles.stage4DescriptionCol}>
                <StageNumber stage="04" />
                <StageDescription
                  mainText={t('security.stage4.description')}
                  subText={t('security.stage4.descriptionSub')}
                  mainClass={styles.stage2Description}
                />
              </Col>
            </Row>
          </div>

          <div className={styles.phoneContainer}>
            <div className={styles.bottomGradientText}>
              <Typography xlSize="hero1" size="h1" lgSize="h1" wrap={true}>
                {t('security.seeYourLatestData')}
              </Typography>
            </div>
            <img src={phoneImg} alt="" className={styles.phoneImg} />
          </div>
        </div>
        <Row className={styles.bottomShadow}></Row>
      </Container>
      <div className={styles.getStartedContainer}>
        <GetStarted desc={t('signUp.signDesc')} />
      </div>
    </>
  );
}

export default Security;
